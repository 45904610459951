@import 'default';

.container {
  overflow: hidden;
  .fore{
    .face{
      padding-top: 10px;
    }
  }
}

.back {
  height: 100%;
  z-index: 1;

  * {
    position: absolute;
    background-repeat: no-repeat;
  }

  #field {
    height: 100%;
    background-image: url(/img/mobile/field.png);

    @include mid {
      background-image: url(/img/field.jpg);

    }

    @include land {
      background-image: url(/img/field.jpg);

    }
  }

  #left {
    background-image: url(/img/mobile/left.png);
    background-position: left bottom;
    bottom: 120px;
    height: 70%;

    @include mid {
      background-image: url(/img/left.png);
      height: 100%;
      bottom: 0;
    }
  }

  #right {
    background-image: url(/img/mobile/right.png);
    background-position: right bottom;
    bottom: 130px;
    height: 70%;

    @include mid {
      background-image: url(/img/right.png);
      height: 100%;
      bottom: 0;
    }
  }

  #gold-1 {
    background-image: url(/img/mobile/gold-1.png);

    @include mid {
      display: none;
    }

    @include land {
      display: none;
    }
  }

  #gold-2 {
    background-image: url(/img/mobile/gold-2.png);

    @include mid {
      background-image: url(/img/gold.png);
    }

    @include land {
      background-image: url(/img/gold.png);
    }
  }

  #front {
    background-image: url(/img/mobile/front.png);

    @include mid {
      background-image: url(/img/front.png);
    }
  }

  #blue {
    display: none;
    background-image: url(/img/blue.png);
    background-size: auto 100%;
    background-position: left bottom;
    height: 70%;

    @include mid {
      display: inherit;
      bottom: 80px;
      left: 0;
      width: 800px;
    }
  }

  #yellow {
    background-image: url(/img/mobile/yellow.png);
    background-size: 100% auto;
    background-position: right bottom;
    bottom: 54px;
    height: 100%;

    @include mid {
      background-image: url(/img/yellow.png);
      background-size: auto 100%;
      bottom: 80px;
      height: 70%;
      right: 0;
      width: 800px;
    }

    @include land {
      bottom: 0px !important;
      background-size: auto 100%;
      background-position: right bottom;
    }

    @include iphone {
      bottom: 75px;
    }

    @include sm {
      bottom: 65px;
    } 
  }

  #footer {
    background-image: url(/img/mobile/footer.png);
    background-size: 100% 100%;
    background-position: center bottom;
    height: 130px;
    bottom: 128px;

    @include mid {
      height: 600px;
      bottom: 0px;
    }

    @include land {
      height: 200px;
      bottom: 0px;
    }
  }

  #bottom {
    background-color: $bg;
    height: 129px;
    bottom: 0;

    @include mid {
      display: none;
    }

    @include land {
      display: none;
    }
  }
}

.fore {
  z-index: 2;
  display: flex;
  justify-content: center;

  @include land {
    justify-content: flex-start;
    padding-left: 70px;
  }

  .face {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;

    @include land {
      padding: 10px;
      max-width: 400px;
      min-height: 385px;
    }

    @include mid {
      max-width: 970px;
      padding: 53px 36px;
    }

    @include iphone {
      padding-top: 5px;
    }

    @include sm {
      padding-top: 5px;
    }


    #logo {
      margin-bottom: 18px;

      @include land {
        margin-bottom: 8px;
        width: 70%;
      }

      @include mid {
        height: 106px;
        max-width: 100%;
      }

      @include iphone {
        max-width: 230px;
      }

      @include sm {
        max-width: 230px;
      }
    }

    p {
      @include land {
        font-size: rem(14);
      }

      @include mid {
        font-size: rem(48);
        line-height: 66px;
      }
    }

    .buttons{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mid(){
        max-width: 750px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;  
      }
      @include land(){
        flex-direction: row;
        justify-content: center;
      }
      @include lrg{
        max-width: 1000px;
      }
      .button + .button{
        @include land(){
          margin-left: 10px;
        }
      }
    }

    .button {
      width: 260px;
      font-family: 'Cera Pro';
      font-weight: 700;
      height: 45px;      
      border-radius: 45px;
      transition: transform 2s ease;
      flex-shrink: 0;
      font-size: 20px;
      text-align: center;
      line-height: 45px;
      text-transform: uppercase;
      margin-top: 10px;
      @include land {
        height: 45px;
        width: 180px;
        margin-top: 10px;
      }
      
 
      @include mid {
        padding: 0;
        width: 333px !important;
        height: 74px !important;
        line-height: 74px !important;
        font-size: 32.8889px !important;
        border-radius: 74px !important;
        margin-top: 40px !important;
      }      

      @include sm {
        margin-top: 5px;
      }

      &.animated {
        transform: scale(1.05);
        transition: transform 2s ease;
      }
      
      &#bonuses{
        color: white;
        background: linear-gradient(0.16deg, #1C1B50 0.28%, #3937A0 51.57%, #5852FF 99.72%);
        box-shadow: 0px 6.55696px 10.9283px rgba(0, 0, 0, 0.5), inset 0px 2.18565px 2.18565px #CBCDFF;
        &:hover{
          background: linear-gradient(0.16deg, #3D3B8D 0.28%, #8497FF 51.57%, #89B8FF 99.72%);
          box-shadow: 0px 6.55696px 10.9283px rgba(0, 0, 0, 0.5), inset 0px 2.18565px 2.18565px #CBCDFF;
        }
      }
      
      &#game{
        color: black;
        background: #F8DC0E;
        box-shadow: 0px 3.98734px 6.64557px rgba(0, 0, 0, 0.5), inset 0px 1.32911px 1.32911px #EC7100;
        &:hover{
          background: linear-gradient(180deg, rgba(255, 145, 0, 0.5) 0%, rgba(0, 49, 174, 0.5) 100%), #FFEF7B;
          background-blend-mode: overlay, normal;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
        }
      }
    }    

    

    .subscribe {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 330px;
      width: 100%;
      // margin-top: auto;

      @include land {
        max-width: 300px;
      }

      &--info{
        padding: 6px 20px;
        background-color: #F8DC0E;
        color: black;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        font-weight: 500;
        text-align: center;
        top: 0;
        width: calc(100% + 70px);
        height: fit-content;
        margin-top: auto;
        margin-bottom: 14px;
        transition: all .15s;
        @include mid{
          padding: 6px 8px;
          margin-bottom: 0;
          max-width: 330px;
          width: 100%;
          border-radius: 9px 9px 0 0;
        }
        &.active{
          margin-bottom: 36px;
          @include mid{
            margin-bottom: 0;
          }
        }
      }

      &--container {
        display: flex;
        flex-direction: column;

        @include mid {
          padding: 20px 22px 26px;
          border-radius: 0 0 9px 9px;
          background-color: rgba(white, .75);
        }

        .label {
          color: white;
          z-index: 1;
          margin-bottom: 5px;
          display: none;

          @include land {
            display: block;
            font-size: rem(12);
          }

          @include mid {
            display: block;
            color: black;
          }
        }

        &.invalid {
          .input-container {
            border-color: $red;
            transition: all .15s;
            z-index: 3;

            #alert {
              opacity: 1;
              transition: all .15s;
            }
          }

          .error {
            background-color: $red;
            z-index: 2;
            transform: translateY(-80%);
            transition: transform .15s;
          }

        }

        &.success {
          .input-container {
            border-color: $success;
            transition: all .15s;
            z-index: 3;
          }

          .error {
            background-color: $success;
            z-index: 2;
            transform: translateY(-80%);
            transition: transform .15s;
          }

        }
      }

      &--input {
        display: flex;
        position: relative;

        .error {
          padding: 5px 16px 10px;
          text-align: center;
          border-radius: 5px 5px 0 0;
          position: absolute;
          z-index: -1;
          transform: translateY(3px);
          transition: transform .15s;
          color: black;
        }

        .input-container {
          background-color: white;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          box-shadow: 0px 0px 4px rgba(255, 108, 107, 0.5);
          border-radius: 5px;
          border: 1px solid #7C7C7C;
          transition: all .15s;

          input {
            border: none;
            outline: none;
            text-indent: 16px;
            line-height: 40px;
            height: 40px;
            font-family: inherit;
          }

          #alert {
            opacity: 0;
            margin: 0 12px;
            transition: all .15s;
          }
        }
      }

      &--button {
        background-color: $yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        margin-top: 12px;
        font-size: rem(15);
        text-align: center;
        height: 40px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(255, 145, 0, 0.5) 0%, rgba(0, 49, 174, 0.5) 100%), #F8DC0E;
        background-blend-mode: overlay, normal;
        transition: background .15s;

        &:hover {
          background: linear-gradient(180deg, rgba(255, 145, 0, 0.5) 0%, rgba(0, 49, 174, 0.5) 100%), #FFEF7B;
          transition: background .15s;
        }

        &:active {
          background: linear-gradient(180deg, rgba(255, 145, 0, 0.5) 0%, rgba(0, 49, 174, 0.5) 100%), #F89A0E;
          transition: background .15s;
        }

        &:active {
          background: linear-gradient(180deg, rgba(255, 145, 0, 0.5) 0%, rgba(0, 49, 174, 0.5) 100%), #F89A0E;
          transition: background .15s;
        }

        @include mid {
          margin-top: 26px;
        }
      }

      p {
        margin-top: 15px;
        color: white;
        line-height: 19px;
        text-shadow: 0px -0.580134px 0.870201px #1387C8, 0px 0px 0.290067px #12133F, 0px 0.580134px 0.580134px #121240, 0px 1.16027px 4.351px #121223;

        @include land {
          font-size: rem(14);
          margin-top: 10px;
        }

        @include mid {
          font-size: rem(22) !important;
          line-height: 28px;
        }

      }

      .label,
      input,
      input::placeholder,
      .error,
      p {
        font-size: rem(14);
        font-family: 'Cera Pro Medium';
      }
    }
  }
}

.logos {
  top: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg;
  padding: 20px;
  padding-top: 0;
  #copy{
    font-size: 11px;
    color: white;
    margin-top: 40px;
    opacity: .5;
  }
  @include mid {
    display: none;
  }
  @include land{
    display: none;
  }
  .to-left ~ &{
    display: none;
  }
}
