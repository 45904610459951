@font-face{
  font-family: 'Cera Pro';
  src: url(fonts/CeraPro-Regular.woff2) format('woff2')
}

@font-face{
  font-family: 'Cera Pro Black';
  src: url(fonts/CeraPro-Black.woff2) format('woff2')
}

@font-face{
  font-family: 'Cera Pro Bold';
  src: url(fonts/CeraPro-Bold.woff2) format('woff2')
}

@font-face{
  font-family: 'Cera Pro Medium';
  src: url(fonts/CeraPro-Medium.woff2) format('woff2')
}

$bg: #110e20;
$yellow: #FBFF00;
$red: #FF6B6B;
$success: #4ee649;