.choose{
  top: 0;
  &.animatable{
    &.to-right{
      display: none;
    }
  }
  .back{
    background-image: linear-gradient(0deg, $bg 0%, transparent 8%), url(/img/faq-background.png);
    background-position: center;
    @include mid(){      
      background-size: 100% 100%;
    }
  }
  .fore{
    @include land(){
      padding: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .face{
      
    }
    .slider{
      display: flex;
      overflow-x: hidden;
      width: 100%;
      max-width: 100vw;
      @include mid(){
        max-width: 1440px;
      }
      
      & > div {
        width: 100%;
        position: absolute;
        max-width: 100vw;
        @include mid(){
          padding: 0 16px;
          max-width: 1440px;
        }
        @include land(){
          width: 100vw;
        }
      }
    }
    .actions{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-left: -35px;
      @include mid(){   
        margin-left: unset;     
        grid-template-columns: repeat(3, auto);
        column-gap: 60px;
        left: 50%;
        transform: translateX(-50%);
      }
      @include land(){
        
        margin-left: unset;     
        grid-template-columns: repeat(3, auto);
        column-gap: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
      .action{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          @include mid(){
            width: 425px;
          }
        }
        .button{
          cursor: pointer;
          width: 100%;
          @include mid(){
            // max-width: 486px;
            margin-top: 10px;
            padding: 0;
          }
        }
        &:first-child{
          grid-column: 1 / span 2;
          width: 100vw;
          margin-bottom: 30px;
          @include mid(){
            margin: 0;
            width: 425px;
            grid-column: 1 / span 1;
          }
          @include land(){
            margin: 0;
            grid-column: 1 / span 1;
            width: auto;
          }
          & > img{
            width: calc(100vw + 10px);
            transform: translateX(5%);
            
            @include iphone {
              width: calc(100vw + 80px);
            }

            @include land(){
              width: calc(100vw / 3);
            }

            @include mid(){
              width: 425px;
              transform: unset;
            }
          }
          .button{
            display: flex;
            align-items: center;
            width: 192px;
            margin-top: -10px;
            @include mid(){
              width: 338px;
            }
            img{
              width: 93px;
              margin-left: -10px;
              @include mid(){                
                width: 150px;
                margin-left: -17px;
              }
            }
            span{
              margin-left: 10px;
              @include mid(){
                margin-left: 27px;
              }
            }
          }
        }
        &:not(:first-child){
          & > img {
            width: calc(100vw - 200px);
            @include mid(){
              width: calc(100vw - 70px - 30px);
              width: 425px;
            }
            @include land(){
              width: calc(100vw / 3);
            }
          }
          .button{
            width: 100%;
            height: auto;
            max-width: 133px;
            padding: 10px;
            @include mid(){
              width: 396px !important;
              max-width: unset;
              padding: 0;
            }
            @include land(){
              margin-top: -10px;
            }
            & > span{ 
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 10px;
              line-height: 14px;
              @include mid(){
                display: block;
                line-height: 74px;
                flex-direction: row;
              }
            }
            span{
              font-size: 10px;
              letter-spacing: -0.09px;
              @include mid(){
                line-height: 100%;
                font-size: 32.8889px;
              }
              span{
                font-size: 20px;
                line-height: 25px;
                @include mid(){                  
                  line-height: 74px;                  
                  font-size: 32.8889px;
                }
              }
            }
          }
        }
      }
    }

    .faq{
      &.animatable{
        &:not(.to-right){
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .img{
        clear: both;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mid(){
          float: left;
          clear: none;
        }
        .back{
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          order: 2;
          background: none;
          padding: 0 35px;
          justify-content: center;
          margin-left: -15px;
          @include mid(){
            justify-content: flex-start;
            order: 0;
            margin-left: 0;
            margin-top: 10px;
          }
          & *{
            position: relative;
          }
          img{
            width: 15px;
            @include mid(){
              width: 30px;
            }
            
          }
          span{
            font-size: 20px;
            color: white;
            text-transform: uppercase;
            margin-left: 15px;
            @include mid(){
              font-size: 33px;
              margin-left: 30px;
            }
          }
        }
        img{
          width: calc(100vw - 200px);
          @include mid(){
            width: 450px;
          }
          @include land(){
            width: 200px;
          }
        }
      }
      .data{
        font-family: 'Cera Pro';
        font-size: 12px;
        line-height: 18px;
        color: white;
        padding: 0 35px;
        background: url(/img/mobile/gold-3.png);
        background-size: 100% auto;
        @include mid(){
          background: none;
          padding: 0;
        }
        ul{
          padding-inline-start: 10px;
          @include mid(){
            padding-inline-start: 40px;
          }
          li{
            
          }
          li + li {
            margin-bottom: 20px;
          }
        }
      }
    }

    .rules{
      display: none;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;
      color: white;
      height: fit-content;
      padding:0 35px 80px;
      margin-top: 70px;
      width: calc(100vw - 70px);
      left: 50%;
      transform: translateX(-50%);
      @include mid(){
        padding: 0 0 80px;
      }
      &:not(.hidden){
        display: flex;
        transition: top .2s ease-in-out;
      }
      .line{
        width: 100%;
        padding: 1px 0;
        background: linear-gradient(153.6deg, #0000FF -70.11%, #00FFFF 104.82%);
      }
      h2{
        font-family: 'Cera Pro Bold';
        font-size: 20px;
        margin: 35px;
      }
      p{
        font-family: 'Cera Pro';
        font-size: 10px;
        line-height: 18.5px;
        text-align: left;
        @include mid(){
          font-size: 12px;
        }

      }
    }
  }
}