@mixin mid{
  @media screen and (min-width: 900px) {
    @content;
  }
}

@mixin land{
  @media screen and (max-width: 850px) and (orientation: landscape) {
    @content;
  }
}

@mixin lrg{
  @media screen and (min-width: 1900px) {
    @content;
  }
}

@mixin mob(){
  @media screen and (min-width: 360px) {
    @content;
  }
}

@mixin small-iphone{
  @media screen and (min-width: 370px) and (min-height: 530px) {
    @content;
  }
}

@mixin iphone(){
  @media screen and (min-width: 400px) and (min-height: 700px) {
    @content;
  }
}

// @mixin sm {
//   @media screen and (max-width: 320px) {
//     @content;
//   }
// }

@mixin sm {
  @media screen and (max-width: 360px) and (max-height: 640px) {
    @content;
  }
}



@function rem($px){
  @return ($px / 16) * 1rem;
}