@import 'var';
@import 'mix';
@import 'choose';

html, body {
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Cera Pro';
  font-size: rem(16);
  color: white;
  margin: 0;
  padding: 0;
  background-color: $bg;  
}

a{
  text-decoration: none;
}

*{
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

b{
  font-family: 'Cera Pro Black';
  
}

p{
  padding: 0;
  margin: 0;
  font-size: rem(16);
  font-weight: 400;
  line-height: 19px;
  text-shadow: 0px -0.755541px 1.13331px #1387C8, 0px 0px 0.37777px #12133F, 0px 0.755541px 0.755541px #121240, 0px 1.51108px 5.66655px #121223;
  color: white;
  text-align: center;
  b{
    color: inherit;
    text-shadow: inherit;
    font-size: inherit;
  }
}

.vh{
  height: calc(var(--vh, 1vh) * 100);
}

.w-100{
  width: 100%;
}

.absolute{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn{
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;
}

.bg-100{
  background-size: 100% 100%;
}

.animatable{
  transition-property: transform, opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.to-left{
  transform: translateX(-100%);
  opacity: 0;
}

.to-right{
  transform: translateX(100%);
  opacity: 0;
}